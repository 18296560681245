import React from 'react';

import style from '../style/footer.module.css';
const Footer = () => (
  <div className={style.footer}>
    <a href='/feed.xml' className={style.link} target='_blank'>
      订阅
    </a>
    <a href='/talk/' className={style.link}>
      留言
    </a>
  </div>
);

export default Footer;
